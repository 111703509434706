@import '~global/styles';

.container {
  position: relative;
  background-color: #fff;
  margin: 0;
  box-sizing: content-box;
  min-height: calc(100vh - 300px);
}

.filterTable {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 20px;
}

.popTable {
  border-top: 1px solid #d8d8d8;
  transition: height 2s;
  width: 1480px;
}

.mostPopular {
  min-height: 400px;
}