@import '~global/styles';

.productTitle {
  display: grid;
  grid-row-gap: 5px;

  .title {
    width: 100%;
    overflow: hidden;

    & > * {
      display: inline-block;
    }
  }

  .url {
    display: flex;
    align-items: flex-start;
    text-decoration: none;
    word-break: break-all;
    position: relative;

    svg {
      min-width: 30px;
      left: -6px;
    }
  }
}
