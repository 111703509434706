@import '~global/styles';

.smallItems {
  .rowContent {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    padding: 2em 1em;

    &.hidden {
      overflow: hidden;
      height: 321px;
    }
    &.visible {
      overflow: auto;
    }
    &.loading {
      opacity: 0.5;
    }
  }

  .rowItem {
    flex-basis: 10%;
    background-color: transparent;
    display: flex;
    justify-content: center;
  }
}

.wrap {
  display: flex;
  width: 100%;
  position: relative;
}

.message {
  justify-content: center;
  font-size: $font-size-small;
}
