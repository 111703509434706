@import '~global/styles';

.checkboxContainer{
  position: absolute;
  top: -100px;
  right: 0;
  z-index: 10000;
  left: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  
  & > div { 
    display: inline-block;
  }
}

.showDeleteImageBtn {
  position: absolute;
}