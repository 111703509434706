.productImage {
    padding-top: 8px;
    padding-left: 12px;
    padding-right: 8px;
    height: 257px;
    width: 131px;
    cursor: pointer;

    &:hover {
        transform: scale(1.1);
        transition: transform 0.1s ease-in-out;
    }

    img {
        object-fit: cover;
        overflow: hidden;
    }

    img,
    > div:first-child {
        width: 107.89px;
        height: 164px;
    }

    .productInfo {
        display: grid;

        .productTitle {
            width: 107.89px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        > div {
            height: 15px;
        }

        .nameEllipsis {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}