@import '~global/styles';

.priceFilterButton {
  display: flex;
  align-items: center;
  width: 126px;
  height: 36px;
  background-color: white;
  border-left: 1px solid rgba(151, 151, 151, 0.19);
  border-right: none;
  border-top: none;
  border-bottom: none;
  fill: $text-light;
  padding: 0 15.5px;
  cursor: pointer;
  overflow: hidden;
  &_inside {
    position: relative;
    padding-left: 28px;

    svg {
      position: absolute;
      left: 0;
      top: -4px;
    }
  }

  &.disabled {
    color: $text-light;
    border-left: 1px solid rgba(151, 151, 151, 0.19);
    border-right: none;
    border-top: none;
    border-bottom: none;
    background-color: white;
    fill: $text-light;
    cursor: auto;

    &.mini {
      border: none;
      background: $white;
    }

    &.text {
      opacity: 1;
    }

    opacity: 0.5;
  }

  &.active {
    color: $white;
    background-color: $orange;
    border-radius: 5px;
    border-left: transparent;
    span {
      color: $white;
    }
    svg {
      fill: $white;
    }
  }

  &:focus,
  &:active {
    outline: none;
  }
}

.modalWrapper {
  position: fixed;
  background: $white;
  padding: 10px;
  cursor: move;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  z-index:99999;
  width: 260px;
  height: 410px;

  .modalHeader {
    display: flex;
    justify-content: space-between;
    padding: 10px 0 5px 0;

    .modalTitle {
      display: flex;
      align-items: center;
      padding-top: 10px;
      padding-left: 8px;
    }
  }

  .modalBody {
    cursor: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 8px;
    position: relative;
    gap: 20px;

    .optionTitle {
      font-size: 14px;
      // transform: scale(0.916);
      // transform-origin: 0% 0%;
    }

    .optionSubtitle {
      font-size: 14px;
      color: #999;
      // transform: scale(0.75);
      // transform-origin: 0% 0%;
    }

    .sliderContainer {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      .sliderLabelText {
        font-family: $font-family;
      }
      [class="MuiSlider-rail"]{
        opacity: 1;
      }
    }

    .sliderValuesContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      color: #666666;

      span{
        border: 0.5px solid #979797;
        border-radius: 5px;
        width: 70px;
        height: 28px;
        align-items: center;
        display: inline-flex;
        justify-content: center;
      }
    }
  }
  div[class*="loading-container"]{
    top: 34px;
  }
  .modalFooter {
    position: absolute;
    bottom: 0;
    left: 0;
    background: $white;
    padding: 0 0 35px;
    width: 100%;

    button {
      margin: 0 auto;
    }
  }
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  background: rgba(255, 255, 255, 0.3);
  width: 100%;
  height: 100%;
}

.modalContainer {
  justify-content: center;
  position: relative;
  z-index: 99999;
  cursor: move;
}

.rangeModal{
    margin-right: 10px;
    display: flex;
    align-items: flex-end;
}
