.form {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 14px;
    z-index: 11;
    margin-bottom: 20px;

    .flex1 {
        flex: 1;
    }
}

.ttile {
    display: flex;
    position: sticky;
    z-index: 11;
    background-color: white;

    div {
        font-size: 16px;
        color: #666666;
        padding-left: 5px;
        padding-top: 10px;
    }
}

.theadWrap {
    position: sticky;
    top: -1.7px;
    background: #fff;
    z-index: 12;
    padding: 0;
    &>div:last-child{margin-bottom: 0;}
}

.thead {
    display: flex;
    flex-direction: row;
    align-items: center;

    .th {
        font-size: 14px;
        color: #484848;
        padding: 0 5px;
        height: 38px;
        line-height: 28px;
        padding-top: 10px;
    }
}

.smallTheadWrap {
    display: flex;
    flex-direction: row;

    .smallThead {
        font-size: 12px;
        transform: scale(calc(11 / 12));
        transform-origin: 0% 0%;
        color: #74B6B6;
        padding: 10px 5px;
    }
}

// 表格行自定义样式，除最后一行外，每一行都加下外边框
.tr {
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
    height: 42px;
}

.tr:last-of-type {
    border-bottom: none;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.shadowBg {
    background-color: rgba($color: #74B6B6, $alpha: 0.13);
}

.greyBg {
    background-color: #edefee;
}

.shadowBorderRight {
    border-right: 1px solid rgba($color: #01A699, $alpha: 0.4);
}

.deepBorderRight {
    border-right: 2px solid #01A699;
}

.normalBorderRight {
    border-right: 1px solid rgba($color: #000000, $alpha: 0.05);
}

.highlight {
    color: #74B6B6 !important;
}

.clickable {
    cursor: pointer;
    color: rgb(100, 147, 146);
    padding-left: 5px !important;
    padding-right: 5px !important;
    display: flex;
    align-items: center;
}