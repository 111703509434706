.vendorWrapper {
    width: 150px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        max-width: 150px;
        max-height: 50px;
    }

    svg {
        max-width: 150px;
        max-height: 50px;
    }
}

.form {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

    .count {
        width: 60px;
        height: 60px;
        border-radius: 60px;
        background-color: #01A699;
        color: #fff;
        font-size: 12px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;
    }

    .formContent {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .mainInfo {
            font-size: 20px;
            font-weight: normal;
            color: #666;
            white-space: nowrap; /* 不换行 */
            overflow: hidden; /* 隐藏溢出的文本 */
            text-overflow: ellipsis;
        }

        .dateWrapper {
            font-size: 12px;
            font-weight: normal;
            color: #686868;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }

    .flex1 {
        flex: 1;
    }
}

.table {
    max-height: calc(100% - 80px);
    overflow-y: auto;
    overflow-x: hidden;
}