@import '~global/styles';

.modalWrapper {
  position: fixed;
  background: $white;
  border-radius: 3px;
  padding: 10px;
  cursor: auto;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
  z-index: 1000;
  width: 436px;
  height: 370px;
  z-index: 500;
  display: flex;
  flex-direction: column;

  .modalHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid $subtle-gray;
    padding-bottom: 10px;
    cursor: move;
    gap: 6px;

    .headerLeading {
      width: 32px;
      min-width: 32px;
      height: 32px;
      border-radius: 32px;
      color: #fff;
      background-color: #01a699;
      font-size: 11px;
      line-height: 32px;
      text-align: center;
    }

    .headerTitle {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-self: flex-start;
      align-items: flex-start;
      font-size: 14px;
      color: #666666;
      overflow: hidden;

      .headerTitleInner {
        flex: 1;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .headerDate {
        display: flex;
        align-items: center;
        font-family: 'Nunito Sans';
        font-size: 11px;
        color: #999999;
  
        svg {
          margin-bottom: 1px;
        }
      }
    }
  }

  .modalBody {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-bottom: 45px;
    margin: 10px;
    position: relative;
    height: 230px;
    min-height: 230px !important;
    padding-bottom: 0;
    cursor: auto;
    background-color: #fff;
    overflow: hidden;
  }

  .modalFooter {
    bottom: 21px;
    width: 100%;
    button {
      margin: 0 auto;

      .plusIcon {
        opacity: 0.3;
        width: 20px;
      }
    }
  }

  .modelFooterExpanded {
    width: 100%;
    padding: 10px;
    background-color: #fff;

    button {
      margin: 0 auto;

      .plusIcon {
        opacity: 0.3;
        width: 20px;
      }
    }
  }
}

.expandIcon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  min-width: 20px;
}

.bigModal,
.lineBreakCurrencyModal.bigModal {
  width: fit-content;
  width: 870px;
  height: 510px;
  height: fit-content;
  background: $white;
  z-index: 100000;

  .modalBody {
    overflow: scroll;
    width: 830px;
    height: 400px;
    max-height: 400px;
  }
}

.lineBreakCurrencyModal:not(.bigModal):not(.fullScreenModal) {
  height: 390px;

  .modalBody {
    height: 250px;
  }
}

.fullScreenModal,
.lineBreakCurrencyModal.fullScreenModal {
  width: calc(100vw - 80px);
  // height: calc(100vh - 120px);   // 65px 是 Header 的高度, 全屏模态框也要在 Header 下面
  height: 100%;   // 65px 是 Header 的高度, 全屏模态框也要在 Header 下面
  position: fixed;
  // 218px 是外层 div 设置的偏移量, 这里要修正过来
  left: 85px;
  // -180 是外层 div 设置的偏移量, 这里要修正过来
  top: 120px;
  z-index: 100000;
  cursor: default;
  
  .modalHeader {
    cursor: default;
  }

  .bodyWrapper {
    height: calc(100% - 50px);
  }

  .modalBody {
    overflow: scroll;
    width: 100%;
    height: calc(100% - 86px);
  }
}

.exportExcelButton {
  height: 24px !important;
  line-height: 24px !important;
  font-size: 12px !important;
  color: #888 !important;
  transform: all 0.3s ease-in-out;
  padding-inline: 10px !important;
}

.exportExcelButton:hover {
  color: #666 !important;
  transform: all 0.3s ease-in-out;
}
