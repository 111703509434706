@import '~global/styles';

.show-more {
  position: relative;

  .show-more-dialog {
    position: absolute;
    right: 0px;
    top: 20px;
    border-radius: 2px;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.2);
    padding: 10px;
  }

  .item {
    &:hover {
      cursor: pointer;
      span {
        color: $orange;
      }
    }
  }
}

.showMoreLookbook {
  .item {
    padding: 5px 0;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    flex-direction: row;
    &:last-child {
      border-bottom: transparent;
      padding-bottom: 0;
      margin-right: 8px;
    }
    svg {
      margin-right: 10px;
      height: 18px;
      width: 18px;
    }
    &:hover {
      svg {
        fill: $orange;
      }
    }
  }
}
