@import '~global/styles';

.modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  background: rgba(255, 255, 255, 0.1);
  width: 100%;
  height: 100%;
}

.taxFilter {
  width: 259px;
  background: #FBFBFB;
  box-shadow: 0px 2px 28px 4px rgba(0,0,0,0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
  font-size: 14px;
  position: fixed;
  left: 390px;
  top: 200px;
  z-index: 99999;
  padding-top: 22px;
  padding-bottom: 33px;

  .close {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 215px;

    .title {
      font-size: 18px;
      color: #666;
      margin-left: -40px;
      margin-bottom: 20px;
    }

    .regionItem {
      width: 100%;
      position: relative;

      .region {
        margin-left: 18px;
        user-select: none;
      }

      .rate {
        width: 50px;
        height: 24px;
        font-size: 14px;
        color: #666666;
        background: #EFEEEE;
        border: none;
        outline: none;
        text-align: center;
        padding-right: 15px;
        padding-left: 5px;
      }

      .percentSymbol {
        position: absolute;
        right: 2px;
      }
    }

    .checked {
      color: $primary-color;
      
      div, input {
        color: $primary-color !important;
      }
    }
    
    .saveBtn {
      width: 215px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
