.wrapper {
    margin-bottom: 30px;
}

.metricItemTitle {
    font-size: 14px;
    color: #666;
    height: 36px;
    line-height: 36px;
    background-color: #fff;
    border-radius: 4px;
    padding-left: 10px;
    margin-bottom: 20px;
    position: sticky;
    top: 0px;
    z-index: 2;
}

.left {
    width: 120px;
    justify-content: space-between;

    .productCountWrapper {
        font-size: 12px;
        color: #666;
        padding-left: 10px;
        padding-top: 10px;
        position: sticky;
        top: 40px;
        background-color: #f8f7f5;
    
        span {
            color: #01a699;
        }
    }

    .expandButton {
        font-size: 12px;
        color: #01a699;
        padding-left: 10px;
        display: flex;
        gap: 10px;
        cursor: pointer;

        &  svg {
            width: 8px;
            height: 8px;
        }
    }
}

.productsWrapper {
    .productItem {
        cursor: pointer;
        height: 210px;
        padding-left: 12px;
        padding-right: 8px;
        padding-top: 8px;
        width: 131px;

        .productTitle {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 107.89px;
        }
    }
}
